import React from 'react'; // Import useEffect from React
import { Helmet } from'react-helmet'; // Import Helmet for setting the canonical tag
import { Navbar, CTA } from './Components';
import { Footer, Blog, Testimonies, Packages, Header } from './Containers';
import './App.css';

const App = () => {
  return (
    <>
    {/* React Helmet for setting the canonical tag */}
    <Helmet>
        <link rel="canonical" href="https://devtodefy.com/" />
      </Helmet>
       {/* Main content of the home page */}
      <Navbar />
      <Header />
      <Packages />
      <Testimonies />
      <CTA />
      <Blog />
      <Footer />
    </>
  );
};

export default App;

// Note: The use of React Helmet and useEffect is for SEO purposes,
// to ensure that the canonical tag is set correctly.
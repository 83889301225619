import React from 'react';
import './CTA.css';
//import ContactForm from '../ContactForm/ContactForm'; // Import the ContactForm component

const CTA = () => {
  
  return (
    <div className="cta">
      <div className="cta-content">
        <p><a href="termscons">Contact For More Information</a></p>
        <h3>Propel Your Business into the Future, Today. Contact Us Now!</h3>
      </div>
      <div className="cta-btn">
        <button type="button" onClick={() => window.location.href = "tel:0549663157"} ><b>Contact Us</b></button>
      </div>
      {/*{isContactFormOpen && <ContactForm onClose={closeContactForm} />}*/}
    </div>
  );
};

export default CTA;




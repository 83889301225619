import React from 'react'
import {Helmet} from'react-helmet'
import Navbar from '../Components/Navbar/Navbar'
import ContentCreations from '../Sections/ContentCreation/ContentCreation'
import Footer from '../Containers/Footer/Footer'

const ContentCreation = () => {
    
    return (
        <>
        <Helmet>
        <link rel="canonical" href="https://devtodefy.com/contentCreation" />
        </Helmet>
           <Navbar />
           <ContentCreations />
           <Footer/>
        </>
    )
}

export default ContentCreation
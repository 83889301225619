import React from 'react';
import './CareerForm.css'; // Using your existing CSS file

const CareerForm = ({ onClose }) => {
  const handleOverlayClick = (e) => {
    if (e.target.classList.contains('contact-form-overlay')) {
      onClose();
    }
  };

  return (
    <div className="CareerForm-form-overlay" onClick={handleOverlayClick}>
      <div className="CareerForm-form">
        <span className="career-close-btn" onClick={onClose}>×</span>
        <iframe
          title="Career Form"
          src="https://form.jotform.com/241933540744457"
          frameBorder="0"
          style={{ width: '100%', height: '500px' }}
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default CareerForm;
import React from 'react'
import {Helmet} from'react-helmet'  // Helmet for SEO optimization
import Navbar from '../Components/Navbar/Navbar'
import  Req from '../Sections/Requirments/Requirments'
import Footer from '../Containers/Footer/Footer'

const Portfolio = () => {
    return (
        <>
        <Helmet>
        <link rel="canonical" href="https://devtodefy.com/requirements" />
      </Helmet>
           <Navbar /> 
           <Req />
           <Footer />
        </>
    )
}

export default Portfolio
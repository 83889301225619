import React from 'react'
import {Helmet} from'react-helmet'
import Navbar from '../Components/Navbar/Navbar'
import WebDesDevs from '../Sections/WebDesDev/WebDesDev'
import Footer from '../Containers/Footer/Footer'

const WebDesDev = () => {
    return (
        <>
        <Helmet>
        <link rel="canonical" href="https://devtodefy.com/desdev" />
      </Helmet>
           <Navbar />
           <WebDesDevs />
           <Footer/>
        </>
    )
}

export default WebDesDev
import React from 'react'
import {Helmet} from'react-helmet'
import Navbar from '../Components/Navbar/Navbar'
import Career from '../Sections/Careers/Careers'
import Footer from '../Containers/Footer/Footer'

const Careers = () => {
    return (
        <>
            <Helmet>
            <link rel="canonical" href="https://devtodefy.com/careers" />
            </Helmet>
           <Navbar />
           <Career />
           <Footer/>
        </>
    )
}

export default Careers
import React from 'react';
import Article from '../../Components/Articles/Article';
import {blog07, blog08, blog09, blog10, blog11} from './imports';
import './Blog.css';
import { Link } from 'react-router-dom';

const Blog = () => (
  <div className="blog section__padding" id="blog">
    <div className="blog-heading">
      <h1 className="gradient__text" data-aos="slide-up">Don't Miss Out <br/>Stay Informed With Us</h1>
    </div>
    <div className="blog-container">
      <div className="blog-container_groupA" data-aos="slide-right">
       <Link to="/ourFounder"> <Article imgUrl={blog07} date="Click Here!" 
            text=
            {
             <div>
               <h3>Read Now! <br /><br />Read About Your Founder, Why He Started the Company & What His Vision For The Future Is!</h3>
             </div>
            }/>
            </Link>
      </div>
      <div className="blog-container_groupB" data-aos="slide-left">
        <a href="#services">
          <Article imgUrl={blog09} date="" text="Our Packages and What They Contain" />
        </a>
        <a href="#services">
          <Article imgUrl={blog08} date="Coming Soon!" text="Personalized Graphics" />
        </a>
        <a href="#services">
          <Article imgUrl={blog11} date="Coming Soon!" text="Subscribe To Our Newsletter" />
        </a>
        <a href="#services">
          <Article imgUrl={blog10} date="Coming Soon!" text="Our Future Endeavours" />
        </a>
      </div>
      {/*--Design inspired and adapted from @JavaScriptMastery on Youtube*/}
    </div>
  </div>
);

export default Blog;

import './Requirments.css'; // Updated the CSS file name
import { FaLaptopCode, FaServer, FaLayerGroup, FaPalette, FaRocket, FaUserGraduate } from 'react-icons/fa';
import { FaCogs } from 'react-icons/fa';

const Requirements = () => {
  return (
    <div className="requirements-container" id='requirements'>
      <section className='requirements-section'>
        <h1 className="text__h1">
          Requirements
        </h1>

        <p className="requirements-section_subtext">
          Dev To Defy is at the forefront of technology and is committed to fostering growth and excellence.
          <br />
          Gain practical experience to navigate and thrive in today’s competitive job market.
        </p>

        {/* General Internship Requirements */}
        <article className='requirements-article feature-card' data-aos='fade-up'>
          <div className='requirements-heading-container'>
            <h2>General Internship Requirements</h2>
            <FaUserGraduate className="icon" />
          </div>
          <p>
            - Strong interest in the tech industry<br />
            - Basic understanding of relevant tools or technologies<br />
            - Ability to work well in a team environment<br />
            - Strong problem-solving skills<br />
            - Eagerness to learn and adapt to new challenges<br />
            - Excellent communication and time management skills<br />
            - Basic knowledge of version control systems (e.g., Git)<br />
            - Enthusiasm for innovation and technology
          </p>
        </article>

        {/* College/University Internship Requirements */}
        <article className='requirements-article feature-card' data-aos='fade-up' data-aos-delay='200'>
          <div className='requirements-heading-container'>
            <h2>College/University Internship Requirements</h2>
            <FaUserGraduate className="icon" />
          </div>
          <p>
            - Currently enrolled in a related degree program (Computer Science, Engineering, etc.)<br />
            - Basic understanding of web development (HTML, CSS, JavaScript)<br />
            - Willingness to learn and take on new responsibilities<br />
            - Ability to commit to the duration of the internship<br />
            - Strong communication skills<br />
            - Basic experience with coding or software development projects<br />
            - Ability to collaborate with team members on group projects<br />
            - Initiative to apply academic knowledge to real-world scenarios
          </p>
        </article>

        {/* Front End Developer/Engineer Internship */}
        <article className='requirements-article feature-card' data-aos='fade-up' data-aos-delay='400'>
          <div className='requirements-heading-container'>
            <h2>Front End Developer/Engineer Internship</h2>
            <FaLaptopCode className="icon" />
          </div>
          <p>
            - Proficiency in HTML, CSS, and JavaScript<br />
            - Experience with React.js or other front-end frameworks<br />
            - Understanding of responsive design principles<br />
            - Ability to translate UI/UX designs into interactive web applications<br />
            - Basic knowledge of version control systems (e.g., Git)<br />
            - Strong problem-solving and debugging skills<br />
            - Attention to detail and a keen eye for design consistency<br />
            - Ability to collaborate with designers and back-end developers
          </p>
        </article>

        {/* Back End Developer/Engineer Internship */}
        <article className='requirements-article feature-card' data-aos='fade-up' data-aos-delay='600'>
          <div className='requirements-heading-container'>
            <h2>Back End Developer/Engineer Internship</h2>
            <FaServer className="icon" />
          </div>
          <p>
            - Proficiency in server-side languages like Node.js, Python, or Java<br />
            - Experience with databases such as MongoDB, MySQL, or PostgreSQL<br />
            - Understanding of RESTful API design and implementation<br />
            - Familiarity with web server technologies (e.g., Apache, Nginx)<br />
            - Knowledge of cloud platforms like AWS or Azure is a plus<br />
            - Strong problem-solving skills and attention to scalability<br />
            - Ability to write clean, maintainable, and efficient code<br />
            - Collaboration with front-end developers to integrate user-facing elements
          </p>
        </article>

        {/* Full Stack Developer/Engineer Internship */}
        <article className='requirements-article feature-card' data-aos='fade-up' data-aos-delay='800'>
          <div className='requirements-heading-container'>
            <h2>Full Stack Developer/Engineer Internship</h2>
            <FaLayerGroup className="icon" />
          </div>
          <p>
            - Proficiency in both front-end and back-end technologies<br />
            - Experience with frameworks like React.js and Node.js<br />
            - Strong understanding of databases, RESTful APIs, and version control<br />
            - Ability to build and maintain full-featured web applications<br />
            - Understanding of DevOps practices and CI/CD pipelines is a plus<br />
            - Strong analytical and problem-solving skills<br />
            - Ability to work independently and as part of a team<br />
            - Excellent communication skills to collaborate across departments
          </p>
        </article>

        {/* UX/UI Designer Internship */}
        <article className='requirements-article feature-card' data-aos='fade-up' data-aos-delay='1000'>
          <div className='requirements-heading-container'>
            <h2>UX/UI Designer Internship</h2>
            <FaPalette className="icon" />
          </div>
          <p>
            - Proficiency in design tools such as Adobe XD, Figma, or Sketch<br />
            - Strong understanding of user-centered design principles<br />
            - Experience with wireframing and prototyping<br />
            - Ability to conduct user research and usability testing<br />
            - Strong visual design skills with an eye for typography, color, and layout<br />
            - Knowledge of front-end technologies to collaborate with developers<br />
            - Ability to create and maintain design systems<br />
            - Strong communication skills to articulate design decisions and collaborate with stakeholders
          </p>
        </article>

        {/* What We Offer */}
        <article className='requirements-article feature-card' data-aos='fade-up' data-aos-delay='1200'>
          <div className='requirements-heading-container'>
            <h2>What We Offer</h2>
            <FaRocket className="icon" />
          </div>
          <p>
            - Hands-on experience with real projects<br />
            - Opportunities for growth and learning<br />
            - Flexible work environment<br />
            - Potential for future employment
          </p>
        </article>

        {/* Our Commitment */}
        <article className='requirements-article feature-card' data-aos='fade-up' data-aos-delay='1400'>
          <div className='requirements-heading-container'>
            <h2>Our Commitment</h2>
            <FaCogs className="icon" />
          </div>
          <p>
            We are committed to providing a supportive environment where interns can thrive. Our goal is to ensure that every intern gains valuable skills and experiences that will contribute to their professional growth.
          </p>
        </article>
      </section>
    </div>
  );
}

export default Requirements;

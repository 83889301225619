import React, { useState } from 'react';
import { RiMenu3Line, RiCloseLine, RiArrowDropDownLine, RiArrowDropUpLine } from 'react-icons/ri';
import logo from '../../assets/logo3.png';
import './Navbar.css';
import { Link } from 'react-router-dom';
import ContactForm from '../ContactForm/ContactForm';

const Navbar = () => {
  const [isContactFormOpen, setIsContactFormOpen] = useState(false);
  const [toggleMenu, setToggleMenu] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const openContactForm = () => {
    setIsContactFormOpen(true);
  };

  const closeContactForm = () => {
    setIsContactFormOpen(false);
  };

  const handleLinkClick = () => {
    setToggleMenu(false);
    setDropdownVisible(false); // Close the dropdown when a link is clicked
  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible); // Toggle dropdown visibility
  };

  return (
    <div className="navbar" id='nav'>
      <div className="navbar-links">
        <div className="navbar-links_logo">
          <Link to="/">
            <img src={logo} alt='logo'/>
          </Link>
        </div>

        <div className="navbar-links_container">
          <p><Link to="/" onClick={handleLinkClick} className="hover-underline-animation">Home</Link></p>
          <p><Link to="/mission" onClick={handleLinkClick} className="hover-underline-animation">About Us</Link></p>

          <div className="navbar-links_services" onMouseEnter={() => setDropdownVisible(true)} onMouseLeave={() => setDropdownVisible(false)}>
            <p onClick={toggleDropdown} className="hover-underline-animation">
              Services {dropdownVisible ? <RiArrowDropUpLine /> : <RiArrowDropDownLine />}
            </p>
            {dropdownVisible && (
              <div className="dropdown">
                <p><Link to="/desdev" onClick={handleLinkClick}>Web Design Development</Link></p>
                <p><Link to="/ux_design" onClick={handleLinkClick}>UX/UI Design</Link></p>
                <p><Link to="/contentCreation" onClick={handleLinkClick}>Digital Marketing</Link></p>
              </div>
            )}
          </div>

          <p><Link to="/careers" onClick={handleLinkClick} className="hover-underline-animation">Careers</Link></p>
          <p><a href="/portfolio" onClick={handleLinkClick} className="hover-underline-animation">Portfolio</a></p>
        </div>
      </div>

      <div className="navbar-sign">
        <button type="button" onClick={openContactForm}><b>Contact Us</b></button>
      </div>

      <div className="navbar-menu">
        {toggleMenu
          ? <RiCloseLine color="#000000" size={27} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line color="#000000" size={27} onClick={() => setToggleMenu(true)} />}
        {toggleMenu && (
          <div className="navbar-menu_container scale-up-center">
            <div className="navbar-menu_container-links">
              <p><Link to="/" onClick={handleLinkClick} className="hover-underline-animation">Home</Link></p>
              <p><Link to="/mission" onClick={handleLinkClick} className="hover-underline-animation">About Us</Link></p>

              <p onClick={toggleDropdown} className="hover-underline-animation services__Link">
                Services {dropdownVisible ? <RiArrowDropUpLine /> : <RiArrowDropDownLine />}
              </p>
              {dropdownVisible && (
                <div className="navbar-menu_dropdown">
                  <hr className='navbar__dropdown__divider'/>
                  <p><Link to="/desdev" onClick={handleLinkClick}>Web Design Development</Link></p>
                  
                  <p><Link to="/ux_design" onClick={handleLinkClick}>UX/UI Design</Link></p>
                  
                  <p><Link to="/contentCreation" onClick={handleLinkClick}>Digital Marketing</Link></p>
                  <hr className='navbar__dropdown__divider2'/>
                </div>
              )}

              <p><Link to="/careers" onClick={handleLinkClick} className="hover-underline-animation">Careers</Link></p>
              <p><a href="/portfolio" onClick={handleLinkClick} className="hover-underline-animation">Portfolio</a></p>
            </div>

            <div className="navbar-menu_container-links-sign">
              <button type="button" onClick={openContactForm}><b>Contact Us</b></button>
            </div>
          </div>
        )}
      </div>
      {isContactFormOpen && <ContactForm onClose={closeContactForm} />}
    </div>
  );
};

export default Navbar;
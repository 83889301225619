import React from 'react'
import {Helmet} from'react-helmet'  // Helmet for SEO optimization
import Navbar from '../Components/Navbar/Navbar'
import Founder from '../Sections/OurFounder/OurFounder'
import Footer from '../Containers/Footer/Footer'

const OurFounder = () => {
    return (
        <>
        <Helmet>
        <link rel="canonical" href="https://devtodefy.com/ourFounder" />
      </Helmet>
           <Navbar />
           <Founder />
           <Footer/>
        </>
    )
}

export default OurFounder
import React from 'react';
import './ContactForm.css'; // Using your existing CSS file

const ContactForm = ({ onClose }) => {
  const handleOverlayClick = (e) => {
    if (e.target.classList.contains('contact-form-overlay')) {
      onClose();
    }
  };

  return (
    <div className="contact-form-overlay" onClick={handleOverlayClick}>
      <div className="contact-form">
        <span className="close-btn" onClick={onClose}>×</span>
        <iframe
          title="Contact Form"
          src="https://form.jotform.com/241565669939476"
          frameBorder="0"
          style={{ width: '100%', height: '500px' }}
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default ContactForm;
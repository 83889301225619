import React from 'react';
import './Testimonies.css';
import Feature from '../../Components/Feature/Feature';

const featuresData = [
  {
    title: "Anthony Kaplan, Owner of A.Kaplan Attorneys",
    text: 'Choosing DTD for our website was one of the best decisions we made. Their team’s creativity and dedication brought our vision to life, and the results speak for themselves. We couldn’t be happier!',
  },
  {
    title: 'Timeless Takes Photography',
    text: 'Working with DTD was a game-changer. Their attention to detail is second to none and we loved their personalized approach. Thank you for helping us stand out in the digital world!',
  },
  {
    title: 'Innnovaira',
    text: 'DTD exceeded our expectations. Their design and development processes were amazing. Their team’s expertise and professionalism made our website project a breeze. Highly recommend!',
  }
];

const Testimonies = () => {
  return (
    <div className='features section__padding' id='testimonials'>
      <div className='features-heading' data-aos="slide-right">
        <h1 className='gradient__text'>Our Success Stories</h1>
        <p>Explore how our clients have <b>thrived</b> with our services. Read their <b>success stories</b> and see firsthand how we’ve <b>transformed</b> businesses just like yours. Dive into our <b>testimonials</b> to discover why our clients trust us with their <b>website development</b> needs.</p>

      </div>
      <div className="features-container">
        {featuresData.map((item, index) => (
          <Feature 
            title={item.title} 
            text={item.text} 
            key={item.title + index} 
          />
        ))}
      </div>
      {/*--Design inspired and adapted from @JavaScriptMastery on Youtube*/}
    </div>
  );
};

export default Testimonies;
import React from 'react';
import styled from 'styled-components';
import founderImg from '../../assets/DSCF0506-removebg-preview.png';
import './OurFounder.css'

const OurFounder = () => (
  <Container>
    <Card>
      <CardHeader>
        <Title>About Our Founder</Title>
      </CardHeader>
      <CardContent>
        <TextSection>
          <div className='card-div'>
          <Paragraph>Who am I? My name is Gabriel and I am the founder of DTD (Dev To Defy).</Paragraph>
          <Paragraph>From a young age, I dreamed of changing the world, inspired by visionaries like Einstein, Steve Jobs, and Bill Gates. My journey into technology began with a fascination for solving problems and building robots at just 8 years old. By 13, I discovered programming — the inner workings of computers, their communication, and the limitless possibilities they offered. This ignited a passion that led me to dive deep into coding, design, and everything in between, turning a childhood curiosity into a fulfilling career.</Paragraph>
          <Paragraph>Let me tell you why I started DTD. Do you remember those  moments when you were a kid, gazzing at the stars, imagining the endless possibilities, or when you built something from scratch and felt a rush of creativity and accomplishment? Those dreams of exploration, creation and imagination have always stayed with me. I founded DTD to capture that same sense of wonder and creativity, channeling it into our work to inspire innovation and propel our world forward..</Paragraph>
          <Paragraph>In today's digital age, the internet is our gateway to information, communication, commerce, and community. Yet, amidst the technologically advanced websites, I noticed gaps. Many businesses struggle with outdated sites or are constrained by rigid website builders. I saw an opportunity to change this narrative. Dev To Defy was born not only to deliver exceptional web solutions but also to forge genuine partnerships with clients. Our mission? To redefine web design and development by blending innovation with personalized service, helping businesses stand out and thrive.</Paragraph>
          <Paragraph>At DTD, we're committed to pushing boundaries. We believe in the transformative power of creativity, collaboration, and staying ahead of trends. Our goal is to empower businesses with visually stunning, user-friendly digital solutions that exceed expectations.</Paragraph>
          <List>
            <ListItem><strong>Broadening Our Horizons:</strong> Expect more services, like custom graphics and advanced web functionalities, tailored to your unique needs.</ListItem>
            <ListItem><strong>Trendsetters:</strong> We’re always learning and adapting to deliver the latest in tech and design.</ListItem>
            <ListItem><strong>Community Building:</strong> Creating spaces for knowledge-sharing and fostering success stories.</ListItem>
            <ListItem><strong>Going Green:</strong> Integrating eco-friendly practices because sustainable growth is crucial.</ListItem>
          </List>
          <Paragraph>And yes, don’t think we forgot about AI. With the rapid growth and potential of artificial intelligence, we’re diving right in. At Dev To Defy, we’re excited to announce that a DTD AI is on the horizon, set to revolutionize how we approach digital solutions. Stay tuned as we harness AI to innovate and elevate our services to new heights.</Paragraph>
          <Paragraph>Let’s create something extraordinary together. Whether you’re seeking a standout web presence or a collaborative partnership, Dev To Defy is here to defy the ordinary and craft the extraordinary.</Paragraph>
          <ImageCaption>Gabriel Kaplan, Founder of DTD</ImageCaption>
          </div>
        </TextSection>
        <ImageSection>
          <Image src={founderImg} alt='Founder' />
          <ImageCaption>Founder & Lead Developer of DTD</ImageCaption>
        </ImageSection>
      </CardContent>
    </Card>
  </Container>
);

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 50px 20px;
`;

const Card = styled.div`
  background-color: #ffffff;
  color: #333;
  width: 100%;
  max-width: 1200px;
  border-radius: 20px;
  overflow: hidden;
`;

const CardHeader = styled.div`
  background-color: #9400D3;
  padding: 20px;
  border-radius: 30px;
  font-family: var(--font-family)
`;

const Title = styled.h1`
  color: #ffffff;
  font-size: 3rem;
  font-weight: 800;
  margin: 0;
  text-align: center;

  @media (max-width: 650px) {
    font-size: 2.5rem;
  }

  @media (max-width: 490px) {
    font-size: 2rem;
  }
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin-top: 40px;
  border-radius: 30px;

  @media (max-width: 1050px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const TextSection = styled.div`
  flex: 1;
  padding-right: 20px;
`;

const Paragraph = styled.p`
  font-size: 1.1rem;
  line-height: 1.8;
  color: #333;
  margin-bottom: 20px;
  letter-spacing: 0.5px;
  text-align: justify;

  @media (max-width: 650px) {
    font-size: 1rem;
  }

  @media (max-width: 490px) {
    font-size: 0.9rem;
  }
`;

const List = styled.ul`
  margin: 20px 0;
  padding: 0;
  list-style-type: none;
`;

const ListItem = styled.li`
  font-size: 1.1rem;
  line-height: 1.8;
  color: #333;
  margin-bottom: 10px;
  letter-spacing: 0.5px;
  text-align: justify;

  strong {
    color: #6a0dad;
  }

  @media (max-width: 650px) {
    font-size: 1rem;
  }

  @media (max-width: 490px) {
    font-size: 0.9rem;
  }
`;

const ImageSection = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; /* Ensures content stacks vertically */

  @media (max-width: 1050px) {
    width: 80%;
    margin-top: 3rem; /* Adjust margin top for smaller screens */
    margin-left: 7rem; 
  }

  @media (max-width: 650px) {
    margin-left: 2.5rem;
  }

  @media (max-width: 490px) {
    margin-left: 2rem;
  }
`;

const Image = styled.img`
  max-width: 100%;
  border-radius: 150px;

  @media (max-width: 1050px) {
    margin-top: 3rem;
  }
`;
const ImageCaption = styled.p`
  margin-top: 10px;
  font-size: 1rem;
  color: #666;
  text-align: center;

  @media (max-width: 650px) {
    font-size: 0.9rem; /* Adjust font size for smaller screens */
  }

  @media (max-width: 490px) {
    font-size: 0.8rem; /* Further adjust font size for smallest screens */
  }
`;

export default OurFounder;
import React from 'react'
import {Helmet} from'react-helmet'  // Helmet for SEO optimization
import Navbar from '../Components/Navbar/Navbar'
import UxUi from '../Sections/UXUIDesign/UXUIDesign'
import Footer from '../Containers/Footer/Footer'

const UXUIDesign = () => {
    return (
        <>
        <Helmet>
        <link rel="canonical" href="https://devtodefy.com/ux_design" />
      </Helmet>
           <Navbar />
           <UxUi />
           <Footer/>
        </>
    )
}

export default UXUIDesign
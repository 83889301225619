import React from 'react';
import './Feature.css';

const Feature = ({ title, text, img }) => (
  <div className="features-container__feature" data-aos="slide-left">
    <div className="features-container__feature-title">
      <div />
      <h1>{title}</h1>
    </div>
    <div className="features-container_feature-text">
      <p>{text}</p>
    </div>
    {/*--Design inspired and adapted from @JavaScriptMastery on Youtube*/}
  </div>
);

export default Feature;

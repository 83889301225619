import React, { useState } from 'react';
import './Mission.css';
import { FaCheckCircle, FaClipboardCheck, FaPencilRuler, FaCode, FaRocket, FaLightbulb, FaGem, FaDesktop, FaUsers, FaStackOverflow, FaGlasses } from 'react-icons/fa';
import ContactForm from '../../Components/ContactForm/ContactForm.jsx';
import { Link } from 'react-router-dom';

const Mission = () => {
  const [isContactFormOpen, setIsContactFormOpen] = useState(false); 

  const openContactForm = () => {
    setIsContactFormOpen(true);
  };

  const closeContactForm = () => {
    setIsContactFormOpen(false);
  };
  
  return (
    <div className="about-page">
      <header className="header-section">
        <div className="header-text">
          <h1 className='gradient__text'>About Dev To Defy</h1>
          <p>Innovative Web Design & Development. Stunning UX & UI. Expert Digital Marketing.</p>
          <div className='content_read_btn'>
            <Link to="/ourFounder">
              <button type="button">Read About Our Founder</button>
            </Link>
          </div>
        </div> 
      </header>

      <section className="intro-section">
        <div className="intro-content">
          <h2>Our Mission</h2>
          <p>
            At Dev To Defy, we are dedicated to pushing the boundaries of web design, development, UX/UI design, and digital marketing. Our mission is to transform your digital vision into reality by leveraging cutting-edge technologies and a passionate team of experts.
          </p>
          <p>
            We blend creativity with technology to build custom websites that not only look stunning but also drive results. Our tailored solutions are designed to align perfectly with your brand and objectives, ensuring that your online presence stands out in an increasingly competitive landscape.
          </p>
          <p>
            We believe in the power of innovation and creativity. Whether you're a startup, small business, or an established enterprise, we offer collaborative, client-focused services that exceed your expectations.
          </p>
          <p>
            Our commitment to excellence is unwavering. By staying at the forefront of industry trends and best practices, we create digital experiences that are not only visually stunning but also highly functional and user-friendly.
          </p>
          <p>Together, Let's Dare to Defy with Dev To Defy!</p>
        </div>
      </section>

      <section className="services-section">
        <h2>What We Offer</h2>
        <div className="services-grid">
          <div className="service-card">
            <h3>Web Design & Development</h3>
            <ul>
              <li>Custom website design tailored to your brand</li>
              <li>Responsive development for all devices</li>
              <li>Search engine optimization (SEO)</li>
              <li>Ongoing maintenance and updates</li>
            </ul>
          </div>
          <div className="service-card">
            <h3>UX/UI Design</h3>
            <ul>
              <li>Comprehensive user experience (UX) design</li>
              <li>Visually appealing user interface (UI) design</li>
              <li>Prototyping and interactive wireframing</li>
              <li>Usability testing and feedback implementation</li>
            </ul>
          </div>
          <div className="service-card">
            <h3>Digital Marketing</h3>
            <ul>
              <li>SEO-focused strategies to enhance visibility</li>
              <li>Effective social media marketing campaigns</li>
              <li>Content creation and copywriting for brand authority</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="values-section">
        <h2>Why Choose Us</h2>
        <div className="values-grid">
          <div className="value-card">
            <div className="service-icon">
              <FaLightbulb className="icon" />
            </div>
            <h3>Expertise and Innovation</h3>
            <p>Our team combines years of industry experience with the latest technologies, delivering cutting-edge solutions that exceed expectations.</p>
          </div>
          <div className="value-card">
            <div className="service-icon">
              <FaGem className="icon" />
            </div>
            <h3>Tailored Solutions</h3>
            <p>We customize our web design, development, and UX/UI services to meet your specific needs and goals.</p>
          </div>
          <div className="value-card">
            <div className="service-icon">
              <FaDesktop className="icon" />
            </div>
            <h3>Comprehensive Services</h3>
            <p>From stunning website design to seamless development and user-centric interfaces, we ensure your digital presence is impactful and effective.</p>
          </div>
          <div className="value-card">
            <div className="service-icon">
              <FaUsers className="icon" />
            </div>
            <h3>Client-Centric Approach</h3>
            <p>We believe in collaboration. From the initial consultation to the final launch, we work closely with you to deliver a website that exceeds your expectations.</p>
          </div>
          <div className="value-card">
            <div className="service-icon">
              <FaStackOverflow className="icon" />
            </div>
            <h3>Technology Showcase</h3>
            <p>We leverage the latest technologies like React, Node.js, Figma, and cloud-based solutions to build high-performance websites that are future-proof and scalable.</p>
          </div>
          <div className="value-card">
            <div className="service-icon">
              <FaGlasses className="icon" />
            </div>
            <h3>DTD's Philosophy</h3>
            <p>We believe in pushing the boundaries of what’s possible. Our mission is to empower businesses through exceptional web design and development. We are committed to: <strong>Innovation, Collaboration, Integrity, Excellence & Community.</strong></p>
          </div>
        </div>
      </section>

      <section className="philosophy-section">
        <div className="philosophy-content">
          <h2>Our Design Philosophy</h2>
          <p>Our philosophy is simple: blend creativity with technology to create impactful web experiences. We value innovation, collaboration, and integrity in everything we do. At Dev To Defy, your success is our mission.</p><br />
          <p>We believe in the transformative power of design. Great design goes beyond aesthetics; it's about creating intuitive and engaging experiences that resonate with your audience.</p><br/>
          <p>Our approach is rooted in understanding your brand's essence, your target audience, and your goals. By combining creativity with strategy, we craft designs that not only look beautiful but also drive results.</p><br/>
          <p>We are committed to innovation and staying ahead of industry trends. This ensures that our designs not only meet current standards but also anticipate future needs.</p><br/>
          <p>At Dev To Defy, we partner with you to defy expectations and elevate your digital presence.</p>
        </div>
      </section>

      <section className="process-section">
        <div className="process-content">
          <h2>Our Development Process</h2>
          <div className="process-steps">
            <div className="process-step">
              <p>
                From the initial consultation to the final launch, we follow a structured development process to ensure every project is delivered on time and within budget. Our process includes:
              </p><br/>
              <div className="step-icon">
                <FaClipboardCheck className="icon" />
              </div>
              <h3>Discovery and Planning</h3>
              <p>We understand your needs and set a clear roadmap.</p>
            </div>
            <div className="process-step">
              <div className="step-icon">
                <FaPencilRuler className="icon" />
              </div>
              <h3>Design and Prototyping</h3>
              <p>We create visually appealing and user-friendly designs.</p>
            </div>
            <div className="process-step">
              <div className="step-icon">
                <FaCode className="icon" />
              </div>
              <h3>Development and Testing</h3>
              <p>We build and rigorously test to ensure functionality.</p>
            </div>
            <div className="process-step">
              <div className="step-icon">
                <FaRocket className="icon" />
              </div>
              <h3>Launch and Optimization</h3>
              <p>We deploy your site and continuously optimize it for performance.</p>
            </div>
          </div>
        </div>
      </section>

      <section className="values-section">
        <h2>Our Values</h2>
        <div className="values-grid">
        <div className="value-card">
            <FaCheckCircle className="icon" />
            <h3>Relentless Innovation</h3>
            <p>We challenge the status quo by constantly exploring new ideas and technologies, driving forward with bold, creative solutions.</p>
          </div>
          <div className="value-card">
            <FaCheckCircle className="icon" />
            <h3>Synergistic Collaboration</h3>
            <p>We believe in the power of collective genius. By working closely with our clients, we co-create tailored solutions that achieve remarkable results.</p>
          </div>
          <div className="value-card">
            <FaCheckCircle className="icon" />
            <h3>Unwavering Integrity</h3>
            <p>Our foundation is built on trust and honesty. We ensure every interaction and decision is made with transparency and ethical responsibility.</p>
          </div>
          <div className="value-card">
            <FaCheckCircle className="icon" />
            <h3>Pursuit of Excellence</h3>
            <p>Good enough is never enough. We strive for perfection in every detail, ensuring our work exceeds expectations and sets new industry standards.</p>
          </div>
          <div className="value-card">
            <FaCheckCircle className="icon" />
            <h3>Fearless Growth</h3>
            <p>We are committed to continuous evolution, embracing challenges and learning from every experience to fuel our growth and innovation.</p>
          </div>
          <div className="value-card">
            <FaCheckCircle className="icon" />
            <h3>Empowering Transformation</h3>
            <p>We don't just build websites; we empower businesses to transform their digital presence, creating lasting impact and driving meaningful change.</p>
          </div>
        </div>
      </section>
    <section className="cta-section2">
  <h2>Start Your Digital Journey</h2>
  <p>Ready to elevate your online presence? <br/>Jump Ahead of Your Competitors with Dev To Defy!</p>
  <button onClick={openContactForm} className="cta-button2">Get Started</button>
</section>
    {isContactFormOpen && <ContactForm onClose={closeContactForm} />}
  </div>
);
};

export default Mission;

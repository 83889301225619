import React from 'react';
import ReactDOM from 'react-dom/client'; // Note the change here
import App from './App';
import './index.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import { Mission, Portfolio, WebDesDev, UXUIDesign, ContentCreation,OurFounder,Careers,Requirments} from './Routes';
import { ScrollToTop } from './Components';

// Create a root.
const root = ReactDOM.createRoot(document.getElementById('root'));

AOS.init({
  duration: 1000, // Animation duration in ms
  once: true, // Whether animation should happen only once - while scrolling down
});
// Initial render: Render the App component to the root.
root.render(
  <BrowserRouter>
  <ScrollToTop />
  <Routes>
    <Route path='/' element={<App />} />
    <Route path='/mission' element={<Mission />} />
    <Route path='/portfolio' element={<Portfolio />} />
    <Route path='/desdev' element={< WebDesDev/>} />
    <Route path='/ux_design' element={< UXUIDesign/>} />
    <Route path='/contentCreation' element={< ContentCreation/>} />
    <Route path='/ourFounder' element={<OurFounder/>} />
    <Route path='/careers' element={<Careers/>} />
    <Route path='/requirements' element={<Requirments/>} />
  </Routes>
</BrowserRouter>,
);